import React, { useEffect, useState } from "react"
import styled from "styled-components";

import Button from "components/Shared/Button"
import Fetch from "Fetch/Fetch";
import Firebase from "../../../../../Firebase";

import EditTasting from "assets/images/icons/ic_editar.svg";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
    ${props => `
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:flex-start;
        font-size: 2vh;
        color:white;
        height:100%;
        ${props.params?.styles}
`}`

const TastingWrapper = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    align-items:center;
    max-height:75vh;
    width: 50vw;
    overflow-y: auto;
    @media (max-width: 426px) {
        width: 100vw;
    }
`

const TextBox = styled.span`
    ${props => `
        font-size:4vh;
        color:white;
        font-weight:bold;
        ${props?.styles}
`}`

const TastingRow = styled.div`
    ${props => `
        display:${props.loading ? "none" : "flex"};
        flex-direction:row;
        align-items:center;
        justify-content:center;
        width:100%;
        margin-bottom:2vh;
        color: black;
        font-weight:bold;
        font-size:2.2vh;
    `}
`

const EditImage = styled.img`
    width:2vh;
    height:2vh;
    font-size:1vh;  
`

const ButtonWrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:flex-start;
    width:40vw;
    margin-bottom: 2vh;
`

const TastingBox = styled.div`
    ${props => `
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0.5vh;
        padding: 0.5rem 10vh;
        font-size: 3vh;
        color: white;
        cursor: pointer;
        flex: 1 1 0%;
        transition: all 0.1s ease 0s;
        margin-left: 1vh;
        height:2.8vw;
        @media (max-width: 426px) {
            width: 15vw;
            height:2rem;
            max-width: 8vw;
        }
        ${props?.styles}
`}`

const TastingBoxTitle = styled.div`
    ${props => `
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0.5vh;
        padding: 0.5rem 10vh;
        font-size: 3vh;
        color: white;
        cursor: pointer;
        flex: 1 1 0%;
        transition: all 0.1s ease 0s;
        margin-left: 1vh;
        height:2.8vw;
        min-width: 40vw;
        padding:0;
        @media (max-width: 426px) {
            width: 70vw;
            height:2rem;
            
        }
        ${props?.styles}
`}`

const TastingBoxTitleDemo = styled.div`
    ${props => `
        display:flex;
        align-items:center;
        justify-content:center;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 0.5vh;
        font-size: 3vh;
        color: white;
        cursor: pointer;
        flex: 1 1 0%;
        transition: all 0.1s ease 0s;
        margin-left: 1vh;
        height:2.8vw;
        min-width: 40vw;
        @media (max-width: 426px) {
            width: 90vw;
            height:3rem;
            padding: 1vh;
            
        }
        ${props?.styles}
`}`

const TastingBoxWrapper = styled.div`
    width:fit-content;
    &:hover ${TastingBox} {
        background-color: rgba(0, 0, 0, 0.6);
    }
    @media (max-width: 426px) {
        width: 100%;
    }
`
const PanelNoResponsive = styled.div`
@media (max-width: 426px) {
    display:none;
}
`

const TastingId = (props) => {
    const [loading, setLoading] = useState(true)
    const [sessionsList, setSessionsList] = useState([]);
    const f = new Firebase().firebase.default;

    const createEvent = () => {
        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
            window.open("https://editor-dev.uptasting.online/dashboard/events/live/list", "_blank");
        } else {
            window.open("https://www.smarttasting.online/dashboard/events/live/list", "_blank");
        }
    }

    const goToEvent = (event) => {
        window.location.href = "/instructor/" + event;
    }

    const modifyEvent = (event) => {
        if (process.env.REACT_APP_ENVIRONMENT === "dev") {
            window.open("https://editor-dev.uptasting.online/dashboard/events/live/edit/" + event, "_blank");
        } else {

        } window.open("https://www.smarttasting.online/dashboard/events/live/edit/" + event, "_blank");
    }

    const createDemoSession = (eventId) => {
        props.setLoading(true);

        Fetch.generateDemoSession(f.auth().currentUser.uid, eventId).then((data) => {
            goToEvent(data.publicCode)
        }).catch((error) => {
            console.log(error, "demo_session")
        });

    }

    useEffect(() => {
        let uID = "";
        if (f.auth().currentUser) uID = f.auth().currentUser.uid;
        Fetch.getSessions(uID, props.tastingId).then((sessions) => {
            if (sessions.length > 0)
                setSessionsList(sessions);
            else
                setSessionsList(["empty"]);

            setLoading(false);
        });
    }, [props.user]);

    return (
        <Wrapper>
            <TastingWrapper>
                <PanelNoResponsive>
                    <ButtonWrapper>
                        <Button value={ControlTrad.getTrad("back")}
                            onClick={() => props.setTastingId(null)} />
                    </ButtonWrapper>
                </PanelNoResponsive>

                <TextBox styles={"font-weight: bold;font-size: 2.5vh; color:black;"}>{ControlTrad.getTrad("selectAEvent")}</TextBox>
                <TastingRow>
                    <TastingBoxWrapper onClick={() => { createDemoSession(props.tastingId) }}>
                        <TastingBoxTitleDemo styles={"min-width: 40vw; width:30vw; padding:0; justify-content: space-around; margin-top:2vh;"}>
                            <TextBox styles={"font-size:2.5vh;"} >
                                {ControlTrad.getTrad("demoEvent")}
                            </TextBox>
                            <TextBox styles={"font-size:2.5vh; font-weight:normal;"} >{ControlTrad.getTrad("demoEventdesc")}</TextBox>
                        </TastingBoxTitleDemo>
                    </TastingBoxWrapper>
                </TastingRow>
                <TastingRow loading={!loading}>
                    {ControlTrad.getTrad("loading")}
                </TastingRow>
                {sessionsList && sessionsList.map(session => {
                    let date = "";
                    let formattedDate = "Sin fecha";
                    if (session.sessionCode?.fromDate) {
                        date = new Date(session.sessionCode.fromDate).toLocaleString();
                        formattedDate = date.split(" ")[0] + " - " + date.split(" ")[1];
                        formattedDate = formattedDate.split(":")[0] + ":" + formattedDate.split(":")[1];
                    }

                    if (session === "empty") {
                        return (
                            <TastingRow>
                                <TextBox styles={"color:black;font-size:1.8vh; font-weight:normal;"}>{ControlTrad.getTrad("noEvents")}</TextBox>
                            </TastingRow>
                        )
                    } else {

                        return (
                            <TastingRow>
                                <TastingBoxWrapper>
                                    <TastingBoxTitle styles={"min-width: 36.8vw; width:30vw; padding:0; justify-content:space-around;"} value={session.sessionCode?.publicCode} onClick={(e) => goToEvent(e.currentTarget.getAttribute("value"))}>
                                        <TextBox styles={"font-size:2.5vh; font-weight:normal;"}><TextBox styles={"font-size:2.5vh;"}>{ControlTrad.getTrad("codeEvent")}</TextBox> {session.sessionCode?.publicCode}</TextBox>
                                        <TextBox styles={"font-size:2.5vh; font-weight:normal;"} ><TextBox styles={"font-size:2.5vh;"}>{ControlTrad.getTrad("startEvent")}</TextBox> {formattedDate}</TextBox>
                                    </TastingBoxTitle>
                                </TastingBoxWrapper>
                                <PanelNoResponsive>
                                    <TastingBoxWrapper>
                                        <TastingBox styles={"max-width:2.8vw; width: 2.8vw; padding:0;"}>
                                            <TextBox styles={"font-size:3vh; font-weight:normal;"} onClick={() => modifyEvent(session.sessionCode?.publicCode)}>
                                                <EditImage src={EditTasting} alt={"modify event"} />
                                            </TextBox>
                                        </TastingBox>
                                    </TastingBoxWrapper>
                                </PanelNoResponsive>
                            </TastingRow>
                        )
                    }
                })}
            </TastingWrapper>
            <PanelNoResponsive>
                <Button value={ControlTrad.getTrad("createNewEvent")}
                    onClick={createEvent} />
            </PanelNoResponsive>

        </Wrapper>
    );
};

export default TastingId;
