
import React, { useState, useEffect } from "react"
import Element from "../Element";
import InputUserInfo from "../InputUserInfo/Input";
import SelectOptions from "components/Shared/SelectOptions/SelectOptions";
import TermsAndConditions from "components/Shared/TermsAndConditions/TermsAndConditions";
import styled from "styled-components";
import ModalMessage from "components/Sandbox/ModalMessage";
import ControlTrad from "assets/trad/ControlTrad";
import User from "assets/user/User";
import FetchUtils from "Fetch/FetchUtils";
import Button from "components/Shared/Button";
import Colours from "assets/custom/Custom";

const Questions = styled.div`
    width: 90%;
    margin: auto;
`

const Forms = (props) => {
    const [isShown, setIsShown] = useState(false);
    const [isCompleted, setCompleted] = useState(false);

    const WrapperModal = styled.div`
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:space-around;
        width:100%;
        font-size:3vh;
        height: 100%;
        padding: 1vh 0;
        overflow: auto;
    `

    const SpanTxt = styled.span`
        padding-left: 2vh;
        padding-right: 2vh;
    `

    const ErrorText = styled.div`
        margin: 1vh 0;
        display:flex;
        algin-items:center;
        justify-content:center;
        font-size:2vh;
    `

    const loadQuestions = () => {
        let views = [];
        props.params.elements.forEach(element => {
            switch (element.element) {
                case "input":
                    views.push(inputLoad(element));
                    break;
                case "select":
                    views.push(selectLoad(element));
                    break;
                case "terms":
                    views.push(checkLoad(element));
                    break;
                default:
                    break;
            }
        });
        return views;
    }

    const inputLoad = (element) => {
        return (
            <InputUserInfo
                id={element.id}
                title={element.title}
                value={element.value}
                placeholder={element.placeholder}
                onBlur={checkIfIsCompleted} />
        );
    }

    const selectLoad = (element) => {
        return (
            <SelectOptions
                id={element.id}
                title={element.title}
                value={element.value}
                options={element.options}
                optionsStyle={element.optionsStyle}
                onSelect={checkIfIsCompleted} />
        );
    }

    const checkLoad = (element) => {
        return (
            <TermsAndConditions id={element.id} onClick={checkIfIsCompleted} />
        );
    }

    const checkIfIsCompleted = () => {
        let elements = props.params.elements;
        if (elements == null || elements === undefined || elements.length <= 0) {
            return false;
        }

        let completed = true;
        elements.forEach(element => {
            try {
                let value = "";
                if (document.getElementById(element.id).type === "checkbox") {
                    value = document.getElementById(element.id).checked;
                } else {
                    value = document.getElementById(element.id)?.getAttribute("formValue")
                    if (value === null | value === undefined) {
                        value = document.getElementById(element.id).value;
                    }
                }

                if (element.required && (value === '' || value === false)) {
                    completed = false;
                }
            } catch (e) {
                completed = false;
            }
        });
        setCompleted(completed);
    }

    const submitForm = (elements) => {
        let validate = true;
        let obj = {}
        elements.forEach(element => {
            //console.log(elements);
            let value = "";
            if (document.getElementById(element.id).type === "checkbox") {
                value = document.getElementById(element.id).checked;

            } else {
                value = document.getElementById(element.id)?.getAttribute("formValue")
                if (value === null | value === undefined) {
                    value = document.getElementById(element.id).value;
                }
            }

            if (element.required && (value === '' || value === false)) {
                validate = false;
                document.getElementById(element.id + "error").style.visibility = "visible";
            } else {
                let idT = element.id;
                obj[idT] = value
                document.getElementById(element.id + "error").style.visibility = "hidden";
            }

        });
        if (validate) {
            sendInfo(obj);
            props.params.onClick();
        } else if (props.params.errorModal) {
            setIsShown(true)
        }
    }

    const loadErrors = (elements) => {
        let views = [];

        elements.forEach(element => {
            let value = "";
            let textElement = "";
            if (element) {
                if (document.getElementById(element.id)?.type === "checkbox") {
                    value = document.getElementById(element.id).checked;

                } else {
                    value = document.getElementById(element.id)?.getAttribute("formValue")
                    if (value === null | value === undefined) {
                        value = document.getElementById(element.id)?.value;
                    }
                }

                if (element.required && (value === '' || value === false)) {
                    //console.log(element.id, element.id.includes("Age"), "request_age")
                    textElement = element.id.includes("Age") ? ControlTrad.getTrad("ageError") : document.getElementById(element.id + "error")?.textContent
                    views.push(
                        <ErrorText>
                            {textElement}
                        </ErrorText>
                    )
                }
            }

        })

        return views;
    }

    const sendInfo = (obj) => {
        User.setName(obj.inputUserInfo);
        User.setGender(obj.selectOpGender);
        User.setAge(obj.selectOpAge);
        FetchUtils.createOrUpdateUser();
    }

    useEffect(() => {
        let isLoaded = (props?.params?.activeNext !== null && props?.params?.activeNext !== undefined);
        if (isCompleted && isLoaded) {
            submitForm(props?.params?.elements);
        }
    }, [isCompleted, props?.params?.activeNext])

    return (
        <Element params={{ ...props.params, styles: "overflow: auto;" }}>
            <Questions>
                {loadQuestions()}

                <Button
                    styles={`
                        background: ${Colours.getColour("mainColor")};;
                        border: 0.3vh solid ${Colours.getColour("mainColor")};;
                        width: 50%;
                        height: 4vh;
                        padding: 1vh;
                        font-size: 2.5vh;
                        margin-top:1rem;

                        @media (min-width: 426px) and (pointer: coarse) {
                            width: 80%;
                            height: 5vh;
                            padding: 1vh;
                            font-size: 3vh;
                            margin-top:1rem;
                        }

                        @media (min-width: 426px) and (pointer: coarse) and (orientation: landscape) {
                            width: 50%;
                        }
                    `}
                    value={props.params.activeNext === null ? ControlTrad.getTrad("loading") : ControlTrad.getTrad("nextLobby")}
                    active={props.params.activeNext !== null}
                    highligthed={isCompleted}
                    onClick={() => submitForm(props.params.elements)}
                />
            </Questions>


            <ModalMessage
                boxSize={"min-width: 80vh !important; border-radius:1.5vh;"}
                styleHeader={"border-top-left-radius:1.5vh; border-top-right-radius:1.5vh; font-size:2.2vh;"}
                styleMain={"border-bottom-left-radius:1.5vh;border-bottom-right-radius:1.5vh;"}
                media={"min-width: 50vw !important;"}
                isShown={isShown}
                setIsShown={setIsShown}
                title={ControlTrad.getTrad("errorInitalForm")} >

                <WrapperModal>
                    <SpanTxt>{loadErrors(props.params.elements)}</SpanTxt>
                    <Button styles={"width:15vw;height:3vh;display:flex;justify-content:center;"}
                        onClick={() => setIsShown(false)}
                        value={ControlTrad.getTrad("accept")}
                    />
                </WrapperModal>
            </ModalMessage>
        </Element>
    );
}
export default Forms;
