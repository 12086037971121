import React, { useState } from "react";
import styled from "styled-components";
import Logo from "assets/images/logo_white.svg";

import assistantBackground from "../../assets/images/background_assistant.png";
import Firebase from "../../Firebase"
import ModalMessage from "components/Sandbox/ModalMessage";
import imgContentShow from "../../assets/images/contrashow.png";
import imgContentHide from "../../assets/images/contrahide.png";
import Button from "components/Shared/Button";
import Colours from "assets/custom/Custom";
import ControlTrad from "assets/trad/ControlTrad";

const Wrapper = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8)), url("${assistantBackground}") no-repeat center;
  background-size: cover;
  text-align:center;
  display: flex;
  align-items:center;
  justify-content:center;
  height:100vh;
  flex-direction:column;
  &>*{
      margin: 1vh 2vh;
  }
  @media (max-width: 426px) {
    flex-direction : column;
    overflow: auto;
    justify-content: normal;
}
  
`;

const Header = styled.div`
display:flex;
align-items:center;
width:100%;
@media (max-width: 426px) {
    flex-direction: column;
}
`

const Back = styled.div`
    text-align: left;
    color: white;
    background-color: ${Colours.getColour("mainColor")};;
    border-radius: 1vh;
    font-size:1.8vh;
    width: min-content;
    margin-left:15vh;
    width: 8vh;
    height: 4.5vh;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease 0s;
    @media (max-width: 426px) {
        margin-left:0vh;
        margin-top:1rem;
        padding: 0 1rem;
        margin-bottom:2rem;
        width: auto;
        height: 3rem;
        font-size:1.5rem;
    }
`

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    @media (max-width: 426px) {
        display:none;
    }

`

const LogoImg = styled.img`
    width: 30vh;
    height:auto;
    margin-right:22vh;
    @media (max-width: 426px) {
        margin-right:0vh;    
        width: 10rem;
    }
`
const Title = styled.h1`
  ${props => `
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size:4.5vh;
    margin : 0;
    @media (max-width: 426px) {
        font-size:2rem;
    }
    ${props?.styles};
  `}
`
const Hint = styled.h1`
font-size: 2.3vh;
color: rgb(255, 255, 255);
font-weight:normal;
margin:0;
@media (max-width: 426px) {
    font-size:1.5rem;
}
`

const HintClick = styled.h1`
font-size: 2.3vh;
color: rgb(255, 255, 255);
cursor: pointer;
font-weight:normal;
@media (max-width: 426px) {
    font-size:1.5rem;
}
`

const EmailInput = styled.input`
border-radius: 13px;
background-color: white;
font-size: 2.3vh;
padding: 7px;
transition: all 0.1s ease 0s;
margin: 10px auto;
width: 61%;
border: 0 solid;
outline:none;
padding:1vh;
height:4vh;
padding-right:0;
@media (max-width: 426px) {
    padding:0.5rem;
    padding-right:0;
    height:2rem;
    font-size: 1.5rem;
    width: 75%;

}
`

const PwdDiv = styled.div`
border-radius: 13px;
background-color: white;
font-size: 2vh;
padding: 7px;
transition: all 0.1s ease 0s;
margin: 10px auto;
width: 61%;
display: flex;
border: 0 solid;
padding:1vh;
padding-right:0;
height:4vh;
@media (max-width: 426px) {
    padding:0.5rem;
    padding-right:0;
    height:2rem;
    width: 75%;
}

`

const PwdInput = styled.input`
    display: flex;
    width: 87%;
    border: 0px;
    font-size: 2.3vh;
    background-color: white;
    outline:none;
    @media (max-width: 426px) {
        font-size: 1.5rem;
        
    }
`

const DivLogin = styled.div`
    ${props => `
        background-color: rgba(216, 216, 216, 0.5);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 2vh;
        align-items: center;
        ${props.view ? "width: 42%;" : "width: 45%;"}
        @media (max-width: 426px) {
            width: 80vw;
        }
    `}
`

const ButtonDiv = styled.div`
text-align: center;
border-radius: 0.7vh;
background-color: ${Colours.getColour("mainColor")};;
color: white;
font-size: 3vh;
padding: 1vh;
border: 0.2vh solid transparent;
transition: all 0.1s ease 0s;
appearance: none;
cursor: pointer;
width: 60%;
margin-top:1vh;
@media (max-width: 426px) {
    font-size:1.5rem;
}
`

const Error = styled.h2`
color: black;
font-weight:normal;
font-size:2.6vh;
margin:5vh;
margin-top:4vh;
margin-bottom:4vh;
`
const Eye = styled.i`
    background: url('${imgContentHide}') no-repeat center;
    background-size: 80% auto;
    width: 10%;
    cursor: pointer;
`

const InstructorLogin = () => {

    const [view, setView] = useState(0)
    const [popUp1, setPopUp1] = useState(false)
    const [popUp2, setPopUp2] = useState(false)
    const [popUp3, setPopUp3] = useState(false)


    var f = new Firebase().firebase;

    const goHome = () => {
        window.location.replace('../')
    }

    const loginUser = (email, pwd) => {
        if(email.length >= 4 && email.includes("@") && pwd.length >= 6){
            f.auth()
            .signInWithEmailAndPassword(email, pwd)
            .then(function (user) {
                f.auth().currentUser.getIdToken(true).then(function (newIdToken) {
                    //idToken = newIdToken;
                    console.debug("[Upwine Auth] ID Token: ", newIdToken);
                    console.debug(f.auth().currentUser);

                    //Fetch.postLogin(newIdToken)
                    //getSessionCookie(newIdToken);
                    window.location.replace("/instructorHome");
                })

            }).catch(()=>{
                setPopUp1(true)
            })
        }else{
            setPopUp1(true)
        }

    }

    const recoveryPass = (email) => {
        f.auth().sendPasswordResetEmail(email).then(()=>{
            setPopUp2(true)
        }).catch(function (error) {
            setPopUp3(true)
        });
    }

    const changePassType = (e) => {
        if(e.target.parentNode.firstChild.type === 'password') {
          e.target.style.background = "url(" + imgContentHide + ") no-repeat center";
          e.target.style.backgroundSize = "80% auto";
          e.target.parentNode.firstChild.type = 'text';
        } else {
          e.target.style.background = "url(" + imgContentShow + ") no-repeat center ";
          e.target.style.backgroundSize = "80% auto";
          e.target.parentNode.firstChild.type = 'password'
        }
      }

    return (
        <>
            <Wrapper>
                <Header>
                    <Back onClick={(e)=>{ view === 0 ? goHome() : setView(0)}}>{ControlTrad.getTrad("back")}</Back>
                    <LogoWrapper>
                        <a href={"https://www.smarttasting.net/"}><LogoImg src={Logo} /></a>
                    </LogoWrapper>
                    
                </Header>
                <DivLogin view={view === 0}>
                    {view === 0 ?
                        <>
                            <Title>{ControlTrad.getTrad("titleLoginInstructor")}</Title>
                            <Hint>{ControlTrad.getTrad("privateArea")}</Hint>

                            <EmailInput id={"email"} type={"email"} placeholder={ControlTrad.getTrad("email")} />
                            <PwdDiv>
                                <PwdInput  id={"password"} type={"password"} placeholder={ControlTrad.getTrad("pwd")}/>
                                <Eye onClick={(e) => {changePassType(e)}}></Eye>
                            </PwdDiv>
                            <ButtonDiv onClick={(e) => { loginUser(document.getElementById("email").value, document.getElementById("password").value) }}>{ControlTrad.getTrad("logIn")}</ButtonDiv>
                            <HintClick onClick={() => { setView(1) }}>{ControlTrad.getTrad("retrievePwd")}</HintClick>

                        </>
                        :
                        <>
                            <Title styles={"font-weight:600;"} >{ControlTrad.getTrad("retrievePwdTittle")}</Title>
                            <EmailInput id={"email"} type={"email"} placeholder={ControlTrad.getTrad("email")} />
                            <ButtonDiv onClick={(e) => { recoveryPass(document.getElementById("email").value) }}>{ControlTrad.getTrad("retrievePwd")}</ButtonDiv>
                        </>
                    }

                </DivLogin>
            </Wrapper>
            <ModalMessage 
                title={ControlTrad.getTrad("warning")} 
                titleStyle={"display:flex;align-items:center;justify-content:center;font-weight:400;font-size:2.3vh;"} 
                boxSize={"width:40rem; border-radius:1vh;"} 
                styleMain={"border-bottom-left-radius:1vh;border-bottom-right-radius:1vh;"}
                styleHeader={"border-top-left-radius:1vh;border-top-right-radius:1vh;"}
                isShown = {popUp1} 
                setIsShown={setPopUp1}>
                <Error>{ControlTrad.getTrad("error_1")}</Error> 
                <Button value={ControlTrad.getTrad("accept")} styles={"background-color:black; border:none; width:80%; margin-bottom: 2vh;"} onClick={() => setPopUp1(false)}/>
            </ModalMessage>
            <ModalMessage 
                title={ControlTrad.getTrad("warning")} 
                isShown = {popUp2} 
                setIsShown={setPopUp2} 
                action={()=>{setView(0)}}
                titleStyle={"display:flex;align-items:center;justify-content:center;font-weight:400;font-size:2.3vh;"} 
                boxSize={"width:40rem; border-radius:1vh;"} 
                styleMain={"border-bottom-left-radius:1vh;border-bottom-right-radius:1vh;"}
                styleHeader={"border-top-left-radius:1vh;border-top-right-radius:1vh;"} >
                <Error>{ControlTrad.getTrad("info_1")}</Error>
                <Button value={"Aceptar"} styles={"background-color:black; border:none; width:80%; margin-bottom: 2vh;"} onClick={() => { setPopUp2(false); setView(0); }}/>
            </ModalMessage>
            <ModalMessage 
                title={ControlTrad.getTrad("warning")} 
                isShown = {popUp3} 
                setIsShown={setPopUp3}
                titleStyle={"display:flex;align-items:center;justify-content:center;font-weight:400;font-size:2.3vh;"} 
                boxSize={"width:40rem; border-radius:1vh;"} 
                styleMain={"border-bottom-left-radius:1vh;border-bottom-right-radius:1vh;"}
                styleHeader={"border-top-left-radius:1vh;border-top-right-radius:1vh;"} >
                <Error>{ControlTrad.getTrad("error_2")}</Error> 
                <Button value={"Aceptar"} styles={"background-color:black; border:none; width:80%; margin-bottom: 2vh;"} onClick={() => {setPopUp3(false)}}/>
            </ModalMessage>
        </>
    );
};

export default InstructorLogin;
